import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Menu from '../components/Menu'

const NotFoundPage = () => (
  <Layout>
    <section className="top subpage">
      <Menu />
    </section>
    <section className="blocks">
      <div className="container">
        <div className="row block-top justify-content-center false">
          <div className="col-12 col-md-10 col-lg-9 heading">
            <h1 className="underrubrik">
              404
              <br />
              Sidan kunde inte hittas
            </h1>
          </div>
          <div className="col-12 col-lg-11 ingress">
            <p>
              Sidan du söker kunde inte hittas.
              <br />
              <Link to="/">Gå tillbaka</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
